import React, { useState, useEffect } from "react"

const useContainerDimensions = myRef => {
  const getDimensions = () => ({
    width: myRef.current.offsetWidth,
    height: myRef.current.offsetHeight,
  })
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions())
    }

    if (myRef.current) {
      setDimensions(getDimensions())
    }

    window && window.addEventListener("resize", handleResize)

    return () => {
      window && window.removeEventListener("resize", handleResize)
    }
  }, [myRef])

  return dimensions
}

export default useContainerDimensions

import React, { useState, useEffect } from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import styled from "styled-components"
import "../../style.css"

// Function to reorder tasks in the UI
const reorder = (list, startIndex, endIndex) => {
  // copy the list to a new array "result"
  const result = [...list]
  // remove object from "result" array
  const [removed] = result.splice(startIndex, 1)
  //   update "position" property of removed object
  var newPosition = endIndex + 1

  const updatedRemoved = { ...removed, position: newPosition }
  //   insert "removed"object back into array in updated place
  result.splice(endIndex, 0, updatedRemoved)

  // create new array newOrder with updated "position" property in each object
  const newOrder = result.map((res, index) => {
    const newPos = index + 1
    return { ...res, position: newPos }
  })
  // return the new array
  return newOrder
}

const Task = ({ task, index }) => {
  return (
    <Draggable draggableId={task.id} index={index}>
      {provided => (
        <Card
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <CardHeader>{task.title}</CardHeader>
          <CardContent>Position: {task.position}</CardContent>
        </Card>
      )}
    </Draggable>
  )
}

const CompleteTask = React.memo(function CompleteTask({ task, tasks }) {
  return (
    <DropZone>
      {tasks && tasks.length > 0
        ? "Drag Here to Complete Task"
        : "Congrats! No more tasks!"}
    </DropZone>
  )
})

const TaskList = React.memo(function TaskList({ tasks, onSelectTask, taskId }) {
  return tasks.map((task, index) => (
    <Task
      task={task}
      index={index}
      key={task.id}
      onSelectTask={onSelectTask}
      taskId={taskId}
    />
  ))
})

const TaskListDemo = () => {
  const [taskId, setTaskId] = useState(null)
  const [selTask, setSelTask] = useState("")
  const [newPosition, setNewPosition] = useState(null)
  const [isUpdating, setIsUpdating] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false)
  const [tasks, setTasks] = useState([
    { id: "1", title: "Code a new app", completed: false, position: 1 },
    { id: "2", title: "Write a blog post", completed: false, position: 2 },
    { id: "3", title: "Plan my next trip", completed: false, position: 3 },
    {
      id: "4",
      title: "Explore the neighborhood",
      completed: false,
      position: 4,
    },
    { id: "5", title: "Make a new friend", completed: false, position: 5 },
    { id: "6", title: "Call Home", completed: false, position: 6 },
  ])

  function onDragStart(result) {
    const selectedTask = tasks[result.source.index]
    setSelTask(selectedTask)
    const selectedTaskId = parseInt(selectedTask.id)
    setTaskId(selectedTaskId)
  }

  function onDragEnd(result) {
    if (!result.destination) {
      return
    }
    if (
      result.destination.index === result.source.index &&
      result.destination.droppableId === result.source.droppableId
    ) {
      return
    }
    if (isUpdating) {
      return
    }

    if (result.destination.droppableId === "completed") {
      setIsCompleted(true)
      const filteredTasks = tasks.filter(t => t.id != taskId)
      const newFilteredOrder = filteredTasks.map((res, index) => {
        const newPos = index + 1
        return { ...res, position: newPos }
      })
      console.log(newFilteredOrder)
      setTasks(newFilteredOrder)
      setNewPosition(0)
    }

    if (result.destination.droppableId === "list") {
      const updatedTasks = reorder(
        tasks,
        result.source.index,
        result.destination.index
      )
      const updatedPosition = parseInt(
        updatedTasks.find(t => t.id == taskId).position
      )
      setTasks(updatedTasks)
      setNewPosition(updatedPosition)
    }
  }
  const handleSelectTask = () => {
    console.log(selTask)
  }
  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <Droppable droppableId="completed">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={{
              backgroundColor: snapshot.isDraggingOver ? "#1a1805" : "",
              maxHeight: "100px",
              maxWidth: "100%",
              padding: "0px",
            }}
            {...provided.droppableProps}
          >
            <CompleteTask task={selTask} tasks={tasks} />
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <Droppable droppableId="list">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <TaskList
              tasks={tasks}
              onSelectTask={handleSelectTask}
              taskId={taskId}
            />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default TaskListDemo

const DropZone = styled.div`
  margin: 1rem 0;
  height: 100px;
  padding: 2rem;
  text-align: center;
  border: 2px dashed var(--color-text-light);
`
const Card = styled.div`
  margin: 1rem;
  padding: 0.5rem;
  text-align: center;
  background: var(--color-quote-background);
  border: 2px solid var(--color-primary);
`
const CardContent = styled.div`
  color: var(--color-primary);
  margin: 0.5rem;
`

const CardHeader = styled.div`
    color: var(--color-primary);
    line-height: 1;
    word-spacing: 3px;
    font-size: 1.6rem;
    margin-top: 0.5rem;
  }
`

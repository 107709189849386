import React from "react"
import styled from "styled-components"
import { Line } from "react-chartjs-2"

export const LineChart = ({ data }) => {
  return <Line data={data} />
}

const IframeVideoWrapper = styled.div`
  width: 90vw;
  max-width: 700px;

  .video {
    overflow: hidden;
    /* // Calculated from the aspect ration of the content (in case of 16:9 it is 9/16= 0.5625) */
    padding-top: 56.25%;
    position: relative;
    border-radius: var(--radius);
  }

  .video iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`

// LineChart.defaultProps = {
//   src: "https://www.youtube.com/embed/f0HZ9uFghR8",
//   title: "Artwork & Sample Proof App Demo",
// }

export const glossaryTerms = [
    {
      term: "cognitive dissonance",
      definition: "The state of having inconsistent thoughts, beliefs, or attitudes, or behaviors.",
      expanded: "Cognitive dissonance produces a feeling of mental discomfort to provoke an alteration in one of the attitudes, beliefs or behaviors to reduce the discomfort and restore balance.",
      references: [
        { name: "Cognitive Dissonance (simplypsychology.com)", link: "https://www.simplypsychology.org/cognitive-dissonance.html" },
      ],
    },
    {
      term: "de Bono, Edward (1933-2021)",
      definition:
        "Maltese physician, psychologist and author known for originating the term 'lateral thinking'.",
      expanded:
        "Best known for his works 'Lateral Thinking: Creativity Step-by-Step (1970)' and 'Six Thinking Hats (1985)', de Bono wrote 85 books, mainly about thinking strategies and creativity. ",
      references: [
        {
          name: "Edward de Bono (wikipedia.org)",
          link:
            "https://en.wikipedia.org/wiki/Edward_de_Bono",
        },
        {
          name: "Video: Edward de Bono on creative thinking",
          link: "https://youtu.be/UjSjZOjNIJg",
        },
      ],
    },  
    {
      term: "Dichter, Ernest (1907-1991)",
      definition:
        "American psychologist and marketing expert who applied Freudian psychanalytic concepts to business.",
      expanded:
        "Known as 'the father of motivational research', Dr Ernest Dichter was a psychologist, marketing research executive, and originator of practical motivational research in advertising, communications, management, and politics.",
      references: [
        {
          name: "Ernest Dichter (encyclopedia.com)",
          link:
            "https://www.encyclopedia.com/humanities/encyclopedias-almanacs-transcripts-and-maps/dichter-ernest",
        },
        {
          name: "Video: Ernest Dichter - Motivational Research (YouTube)",
          link: "https://youtu.be/qALfP8zeSdg",
        },
      ],
    },
    {
      term: "convergent thinking",
      definition:
        "The opposite of divergent thinking, where the object is to give the one 'correct' answer to a problem.",
      expanded: "The way of thinking with the object of coming up with the accepted or most logical answer to a problem. It can be used in conjunction with divergent thinking to organize and structure ideas with the objective of reaching a single solution.",
      references: [
        { name: "Convergent Thinking (wikipedia.org)", link: "https://en.wikipedia.org/wiki/Convergent_thinking" },
      ],
    },  
    {
      term: "divergent thinking",
      definition:
        "A thinking strategy and method for generating creative ideas by exploring many possible solutions.",
      expanded: "The goal of divergent thinking is to generate as many ideas as possible within a short period of time. It is importent to suspend judgement, allowing all ideas - good and bad - to go on the list. When time is up, we switch to convergent thinking to narrow down the list and expand on the useful ideas.",
      references: [
        { name: "Divergent Thinking (encyclopedia.com)", link: "https://www.encyclopedia.com/medicine/encyclopedias-almanacs-transcripts-and-maps/divergent-thinking" },
        { name: "Divergent Thinking (wikipedia.org)", link: "https://en.wikipedia.org/wiki/Divergent_thinking" },
      ],
    },    
    {
      term: "extrinsic motivation",
      definition:
        "Reward-driven behavior. External (not related to the activity itself) rewards and incentives like praise, prizes, and money are used for motivation.",
      expanded: "Anything we're promised (either by others or by ourselves) or gain as a result of performing the activity is an extrinsic motivator. Unlike with intrinsic motivation, the reward comes from somewhere other than the satisfaction of doing the task itself.",
      references: [
        { name: "Intrinsic and extrinsic motivation from a self-determination theory perspective (Ryan and Deci, 2020)", link: "https://selfdeterminationtheory.org/wp-content/uploads/2020/04/2020_RyanDeci_CEP_PrePrint.pdf" },
        { name: "What is Extrinsic Motivation? (Verywell Mind)", link: "https://www.verywellmind.com/what-is-extrinsic-motivation-2795164" },
      ],
    },      
    {
      term: "flow",
      definition:
        "The mental state in which a person performing some activity is fully immersed in a feeling of energized focus, full involvement, and enjoyment in the process of the activity.",
      expanded: "Named by psychologist Mihály Csíkszentmihályi in 1975, flow is characterized by the complete absorption in what one does, wholly engaged to the point where we let go of or temporarilly suspend our awareness of time, food, and the self.",
      references: [
        { name: "Flow (wikipedia.org)", link: "https://en.wikipedia.org/wiki/Flow_(psychology)"},
        { name: "Video: Flow, The Secret to Happines (TED Talk)", link: "https://www.ted.com/talks/mihaly_csikszentmihalyi_flow_the_secret_to_happiness" },
      ],
    },    
    {
      term: "intrinsic motivation",
      definition:
        "One's motivation, the pleasure and reward, are intrinsic to the activity itself",
      expanded: "Like children at play, people do something because they enjoy doing it and for no other reason.",
      references: [
        { name: "Intrinsic Motivation (Center for Self-Determination Theory)", link: "https://selfdeterminationtheory.org/application-intrinsic-motivation/" },
      ],
    },
    {
        term: "javascript",
        definition:
          "A scripting or programming language that allows you to implement complex features on web pages. The programming language for the web.",
        expanded: "A scripting language for the web that's used for creating dynamically updating content, interactive experiences, animated graphics, and more.",
        references: [
          { name: "What is JavaScript? (MDN Web Docs)", link: "https://developer.mozilla.org/en-US/docs/Learn/JavaScript/First_steps/What_is_JavaScript" },
          { name: "What is JavaScript? (w3schools.com)", link: "https://www.w3schools.com/whatis/whatis_js.asp" },

        ],
      },    
     {
      term: "lateral thinking",
      definition:
        "Problem solving by using an indirect approach to reach a solution through reasoning that is not immediately obvious.",
      expanded: "",
      references: [
        {name:"Lateral Thinking (Official Website)", link:"https://www.lateralthinking.com/"},
        {name:"Lateral Thinking (wikipedia.org)", link:"https://en.wikipedia.org/wiki/Lateral_thinking"},
        { name: "The Most Valuable Skill In Difficult Times Is Lateral Thinking—Here’s How To Do It", link: "https://www.forbes.com/sites/phillewis1/2020/03/20/the-most-valuable-skill-in-difficult-times-is-lateral-thinking-heres-how-to-do-it" },
      ],
    },     
    {
      term: "mindfulness",
      definition:
        "The basic human ability to be fully present, aware of where we are and what we’re doing, and not overly reactive or overwhelmed by what’s going on around us.",
      expanded: "The practice of bringing awareness to what you’re directly experiencing via your senses, or to your state of mind via your thoughts and emotions.",
      references: [
        {name:"Getting Started with Mindfulness (mindful.org)", link:"https://www.mindful.org/meditation/mindfulness-getting-started/"},
    ],
    },
    {
      term: "motivational research",
      definition:
        "A type of marketing research that attempts to explain why people do the things they do.",
      expanded:
        "Motivational research seeks underlying or unconscious motives that drive consumer behavior by attempting to uncover what consumers do not fully understand about themselves.",
      references: [
        {
          name: "Motivational Research",
          link: "https://www.decisionanalyst.com/whitepapers/motive/r",
        },
      ],
    },
    {
      term: "personality",
      definition:
        "The combination of characteristics or qualities that form an individual's distinctive character.",
      expanded:
        "Motivational research seeks underlying or unconscious motives that drive consumer behavior by attempting to uncover what consumers do not fully understand about themselves.",
      references: [
        {
          name: "Motivational Research",
          link: "https://www.decisionanalyst.com/whitepapers/motive/r",
        },
      ],
    },
    {
        term: "python",
        definition:
          "A interpreted high-level general-purpose programming language.",
        expanded: "Python is a popular programming language with many possible uses. Python's simple, easy to learn syntax emphasizes readability and therefore reduces the cost of program maintenance.",
        references: [
          { name: "What is Python? Executive Summary (python.org)", link: "https://www.python.org/doc/essays/blurb/" },
        ],
      },
    {
      term: "vertical thinking",
      definition: "Problem solving with a conventional logical processes.",
      expanded:
        "Vertical thinking takes a traditional step-by-step approach, while lateral thinking involves steps that aren't sequential but may still reach the same end. For example, 4 + 4 = 8 is vertical thinking because the equation moves in a straightforward and specific sequence.",
      references: [
        {
          name: "Vertical thinking vs lateral thinking (study.com)",
          link:
            "https://study.com/academy/lesson/vertical-thinking-vs-lateral-thinking.html",
        },
      ],
    },
  ]
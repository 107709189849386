import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import "../../../style.css"
import Create from "./Create"

const img01 = (
  <StaticImage
    src={`../../../images/rig-01.png`}
    alt="Umbrella"
    placeholder="blurred"
    layout="fixed"
    width={200}
    height={250}
  />
)
const img02 = (
  <StaticImage
    src={`../../../images/rig-02.png`}
    alt="Basket"
    placeholder="blurred"
    layout="fixed"
    width={200}
    height={250}
  />
)
const img03 = (
  <StaticImage
    src={`../../../images/rig-03.png`}
    alt="Timer"
    placeholder="blurred"
    layout="fixed"
    width={200}
    height={250}
  />
)
const img04 = (
  <StaticImage
    src={`../../../images/rig-04.png`}
    alt="Headphones"
    placeholder="blurred"
    layout="fixed"
    width={200}
    height={250}
  />
)
const img05 = (
  <StaticImage
    src={`../../../images/rig-05.png`}
    alt="Hot Drink"
    placeholder="blurred"
    layout="fixed"
    width={200}
    height={250}
  />
)
const img06 = (
  <StaticImage
    src={`../../../images/rig-06.png`}
    alt="Book"
    placeholder="blurred"
    layout="fixed"
    width={200}
    height={250}
  />
)
const img07 = (
  <StaticImage
    src={`../../../images/rig-07.png`}
    alt="Battery"
    placeholder="blurred"
    layout="fixed"
    width={200}
    height={250}
  />
)
const img08 = (
  <StaticImage
    src={`../../../images/rig-08.png`}
    alt="Pencil"
    placeholder="blurred"
    layout="fixed"
    width={200}
    height={250}
  />
)
const img09 = (
  <StaticImage
    src={`../../../images/rig-09.png`}
    alt="Rocket"
    placeholder="blurred"
    layout="fixed"
    width={200}
    height={250}
  />
)
const img10 = (
  <StaticImage
    src={`../../../images/rig-10.png`}
    alt="Suitcase"
    placeholder="blurred"
    layout="fixed"
    width={200}
    height={250}
  />
)
const img11 = (
  <StaticImage
    src={`../../../images/rig-11.png`}
    alt="Car"
    placeholder="blurred"
    layout="fixed"
    width={200}
    height={250}
  />
)
const img12 = (
  <StaticImage
    src={`../../../images/rig-12.png`}
    alt="Screen"
    placeholder="blurred"
    layout="fixed"
    width={200}
    height={250}
  />
)

const Images = ({ selectedNumbers, setView }) => {
  const imageNames =
    selectedNumbers &&
    selectedNumbers.map(num => {
      if (num < 10) {
        return eval("img" + 0 + num)
      } else return eval("img" + num)
    })
  const imgs = [
    img01,
    img02,
    img03,
    img04,
    img05,
    img06,
    img07,
    img08,
    img09,
    img10,
    img11,
    img12,
  ]

  return (
    <>
      <Heading>Dial Up Ideas: Step 2</Heading>
      <p>
        Take a look at the three drawings below. Your assignment is to draw
        something new that's inspired by all three of the provided drawings. It
        can be a new product idea, a comic strip, a funny object... it can be
        anything. The only rule is that it must somehow incorporate all three of
        these drawings.
      </p>
      <p>
        Draw your idea in the box below. Then, give your drawing a title or a
        brief description.
      </p>
      <p>
        When you're done, preview and download your image and share it with your
        world.
      </p>
      <Container>
        {imageNames.map((im, index) => (
          <ImageCard key={index}>{im}</ImageCard>
        ))}
      </Container>
      <Container>
        <BackButton onClick={() => setView("numbers")}>
          Dial New Images
        </BackButton>
      </Container>

      <p>
        To draw, use your finger or stylus on a touchscreen device or a mouse on
        your computer.
      </p>

      <br />
      <Create />
    </>
  )
}

export default Images
const Heading = styled.h1`
  font-size: 33px;
  text-align: center;
  color: var(--color-heading);
`
const Container = styled.div`
  display: flex; //the flex stylings here
  flex-wrap: wrap;
  justify-content: center;
`
const ImageCard = styled.div`
  margin: 0.5rem 0.25rem;
  background-color: #ffffff;
  border-radius: 15px;
  position: relative;
`
const BackButton = styled.button`
  margin: 1rem;
  height: 2rem;
  width: 10rem;
  cursor: pointer;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  border: 2px solid var(--color-text-light);
  background-color: var(--color-heading-black);
  border-radius: 15px;
  :hover {
    background-color: var(--color-heading);
    border: 2px solid var(--color-heading);
  }
`

import React, { useState, useRef } from "react"
import "../../../style.css"
import styled from "styled-components"

const PrintList = ({ ideas }) => {
  const [printView, setPrintView] = useState(false)
  const [showList, setShowList] = useState(false)
  const printableIdeas = [...ideas]
  function printDiv(divName) {
    var printContents = document.getElementById(divName).innerHTML
    var originalContents = document.body.innerHTML

    document.body.innerHTML = printContents

    window.print()

    document.body.innerHTML = originalContents
  }
  const handlePrint = () => {
    setPrintView(true)
    printDiv("printZone")
    setPrintView(false)
  }
  return (
    <div>
      <TextButton onClick={() => setShowList(!showList)}>
        {showList ? "Hide This" : "Show a copy & pastable version of the list"}
      </TextButton>
      {showList && (
        <>
          {printableIdeas
            .filter(id => id.dismissed === false)
            .filter(id => id.parentId === null)
            .map(idea => (
              <div key={idea.position}>
                <h4 style={{ color: "var(--color-text-light)" }}>
                  {idea.text}
                </h4>
                <div style={{ marginLeft: "2rem" }}>
                  <ol>
                    {idea.isParent &&
                      printableIdeas
                        .filter(i => i.parentId === idea.id)
                        .map(childIdea => (
                          <li
                            key={idea.position}
                            style={{ color: "var(--color-primary)" }}
                          >
                            {childIdea.text}
                          </li>
                        ))}
                  </ol>
                </div>
              </div>
            ))}
        </>
      )}
    </div>
  )
}

export default PrintList
const TextButton = styled.button`
  color: var(--color-heading);
  font-size: 20px;
  background-color: var(--color-accent);
  border: 0px;
  cursor: pointer;
`

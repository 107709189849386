import React, { useEffect } from "react"
import useInterval from "../../hooks/useInterval"
import styled from "styled-components"
import "../../../style.css"

const Countdown = ({
  timeRemaining,
  setTimeRemaining,
  isActive,
  setIsActive,
}) => {
  function toggleTimer() {
    setIsActive(!isActive)
  }
  function resetTimer() {
    setTimeRemaining(12000)
    setIsActive(true)
  }
  function resetTimerStop() {
    setTimeRemaining(12000)
    setIsActive(false)
  }

  useInterval(() => {
    if (isActive && timeRemaining > 0) {
      setTimeRemaining(timeRemaining - 1)
    }
  }, 1000)

  return (
    <div>
      {timeRemaining}
      <ResetButton onClick={() => setTimeRemaining(12)}>Reset</ResetButton>
      <ResetButton onClick={toggleTimer}>
        {isActive ? "Stop" : "Continue"}
      </ResetButton>
    </div>
  )
}

export default Countdown

const ResetButton = styled.button`
  margin: 1rem;
  height: 3rem;
  width: 85%;
  cursor: pointer;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  border: 2px solid var(--color-text-light);
  background-color: var(--color-heading-black);
  border-radius: 15px;
`

import React, { useState } from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import styled from "styled-components"
import "../../../style.css"

import {  AiOutlinePlus as PlusIcon } from "react-icons/ai"
// Function to reorder ideas in the UI
const reorder = (list, startIndex, endIndex) => {
  // copy the list to a new array "result"
  const result = [...list]
  // remove object from "result" array
  const [removed] = result.splice(startIndex, 1)
  //   update "position" property of removed object
  var newPosition = endIndex + 1

  const updatedRemoved = { ...removed, position: newPosition }
  //   insert "removed"object back into array in updated place
  result.splice(endIndex, 0, updatedRemoved)

  // create new array newOrder with updated "position" property in each object
  const newOrder = result.map((res, index) => {
    const newPos = index + 1
    return { ...res, position: newPos }
  })
  // return the new array
  return newOrder
}

const Idea = ({ idea, ideas, setIdeas, index }) => {
  const [childText, setChildText] = useState("")
  const [elaborate, setElaborate] = useState([])

  const handleNewChild = () => {
    // const filteredIdeas = ideas.filter(i => i.id !== idea.id)
    const parentIndex = ideas.findIndex(obj => obj.id == idea.id)
    // const newIdea = { ...idea }
    ideas[parentIndex].isParent = true
    console.log(ideas[parentIndex])

    const childId = ideas[ideas.length - 1].id + 1
    // const childId = 11
    const date = new Date()

    const newChild = {
      id: childId,
      dateTime: date,
      text: childText,
      position: childId,
      dismissed: false,
      isParent: false,
      parentId: idea.id,
    }
    setIdeas([...ideas, newChild])
    setChildText("")
    console.log(newChild)
    console.log(ideas)
  }
  return (
    <Draggable draggableId={idea.id.toString()} index={index}>
      {provided => (
        <Card
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <CardHeader>{idea.text}</CardHeader>
          <CardContent>
            <Elaborations>
              {idea.isParent
                ? ideas
                    .filter(i => i.parentId === idea.id)
                    .map((id, index) => (
                      <Elaboration key={index}>{id.text}</Elaboration>
                    ))
                : ""}
            </Elaborations>
            {elaborate.includes(idea.id) ? (
              <form
                onSubmit={event => {
                  event.preventDefault()
                  handleNewChild()
                }}
              >
                <Container style={{ alignItems: "baseline" }}>
                  <div style={{ display: "flex", flexGrow: 5 }}>
                    <InputBox
                      placeholder="elaborate on this..."
                      value={childText}
                      onChange={event => setChildText(event.target.value)}
                    />
                  </div>
                  <div style={{ display: "flex", flexGrow: 1 }}>
                    <RectButton type="submit">
                      <PlusIcon />
                    </RectButton>
                    <RectButton
                      onClick={() =>
                        setElaborate(elaborate.filter(el => el !== idea.id))
                      }
                    >
                      Done
                    </RectButton>
                  </div>
                </Container>
              </form>
            ) : (
              <TextButton onClick={() => setElaborate([...elaborate, idea.id])}>
                Elaborate
              </TextButton>
            )}
          </CardContent>
        </Card>
      )}
    </Draggable>
  )
}

const DismissIdea = React.memo(function DismissIdea({ idea, ideas }) {
  return (
    <DropZone>
      {ideas && ideas.length > 0
        ? "Drag Here to Dismiss Idea"
        : "Oh, no! No more ideas!"}
    </DropZone>
  )
})

const IdeasList = React.memo(function IdeasList({
  ideas,
  setIdeas,
  onSelectIdea,
  ideaId,
}) {
  return ideas
    .filter(i => i.parentId === null)
    .map((idea, index) => (
      <Idea
        ideas={ideas}
        setIdeas={setIdeas}
        idea={idea}
        index={index}
        key={idea.id}
        onSelectidea={onSelectIdea}
        ideaId={ideaId}
      />
    ))
})

const IdeaList = ({ ideas, setIdeas }) => {
  const [ideaId, setIdeaId] = useState(null)
  const [selIdea, setSelIdea] = useState("")
  const [newPosition, setNewPosition] = useState(null)
  const [isUpdating, setIsUpdating] = useState(false)
  const [isDismissed, setIsDismissed] = useState(false)
 
  function onDragStart(result) {
    const selectedIdea = ideas[result.source.index]
    setSelIdea(selectedIdea)
    console.log(selectedIdea)
    const selectedIdeaId = parseInt(selectedIdea.id)
    setIdeaId(selectedIdeaId)
  }

  function onDragEnd(result) {
    if (!result.destination) {
      return
    }
    if (
      result.destination.index === result.source.index &&
      result.destination.droppableId === result.source.droppableId
    ) {
      return
    }
    if (isUpdating) {
      return
    }

    if (result.destination.droppableId === "dismissed") {
      setIsDismissed(true)
      const filteredIdeas = ideas.filter(t => t.id != ideaId)
      const newFilteredOrder = filteredIdeas.map((res, index) => {
        const newPos = index + 1
        return { ...res, position: newPos }
      })
      setIdeas(newFilteredOrder)
      setNewPosition(0)
    }

    if (result.destination.droppableId === "list") {
      const updatedIdeas = reorder(
        ideas,
        result.source.index,
        result.destination.index
      )
      const updatedPosition = parseInt(
        updatedIdeas.find(t => t.id == ideaId).position
      )
      setIdeas(updatedIdeas)
      setNewPosition(updatedPosition)
    }
  }
  const handleSelectIdea = () => {
    console.log(selIdea)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <Droppable droppableId="dismissed">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={{
              backgroundColor: snapshot.isDraggingOver ? "#1a1805" : "",
              maxHeight: "100px",
              maxWidth: "100%",
              padding: "0px",
            }}
            {...provided.droppableProps}
          >
            <DismissIdea idea={selIdea} ideas={ideas} />
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <Droppable droppableId="list">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <IdeasList
              ideas={ideas}
              setIdeas={setIdeas}
              onSelectIdea={handleSelectIdea}
              ideaId={ideaId}
            />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default IdeaList
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
const TextButton = styled.button`
  color: var(--color-heading);
  font-size: 18px;
  background: var(--color-quote-background);
  border: 1px solid var(--color-primary);
  border-radius: 15px;
  padding: 0.5rem;
  cursor: pointer;
  margin: 0.5rem;
`
const DropZone = styled.div`
  margin: 1rem 0;
  height: 100px;
  padding: 2rem;
  text-align: center;
  border: 2px dashed var(--color-text);
`
const Card = styled.div`
  margin: 1rem;
  padding: 0.5rem;
  text-align: center;
  background: var(--color-quote-background);
  border: 2px solid var(--color-primary);
  border-radius: 15px;
`
const CardContent = styled.div`
  color: var(--color-primary);
  margin: 0.5rem;
`
const Elaborations = styled.div`
  margin: 1rem;
  padding: 0.5rem;
  text-align: left;
`
const Elaboration = styled.div`
  color: var(--color-text);
  margin: 0.5rem;
`

const CardHeader = styled.div`
    color: var(--color-primary);
    line-height: 1;
    word-spacing: 3px;
    font-size: 1.6rem;
    margin-top: 0.5rem;
  }
`
const InputBox = styled.input`
  border: 2px solid var(--color-heading);
  border-radius: 4px;
  background: var(--color-quote-background);
  color: #fff;
  width: 100%;
  height: 40px;
  margin-bottom: 15px;
  padding: 5px;
  maxlength: 50;
`

const RectButton = styled.button`
  margin: 0.5rem;
  height: 1.8rem;
  cursor: pointer;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  border: 2px solid #fff;
  background-color: var(--color-heading-black);
  border-radius: 5px;
`

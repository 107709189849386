import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import Countdown from "./Countdown"
import IdeaList from "./IdeaList"
import PrintList from "./PrintList"
import "../../../style.css"
import { GrCheckmark as CheckIcon } from "react-icons/gr"
import useInterval from "../../hooks/useInterval"

const Introduction = () => {
  return (
    <div>
      <p>
        The aim of this game is to come up a great solution to your question or
        problem. We begin with a freeflow of ideas known as "divergent
        thinking", where we create an unfiltered list of many possibilities. In
        this first step, we are going for quantity over quality. There are no
        bad ideas.
      </p>
    </div>
  )
}
const DivergentGame = () => {
  const [timeRemaining, setTimeRemaining] = useState(90)
  const [gameClock, setGameClock] = useState(180)
  const [ideaGoal, setIdeaGoal] = useState(10)
  const [timeGoal, setTimeGoal] = useState(60)
  const [isActive, setIsActive] = useState(true)
  const [ideasSet, setIdeasSet] = useState(false)
  const [timeSet, setTimeSet] = useState(false)
  const [gameOn, setGameOn] = useState(false)
  const [viewResults, setViewResults] = useState(false)
  const [text, setText] = useState("")
  const [ideaId, setIdeaId] = useState(1)
  const [ideas, setIdeas] = useState([])
  const quickNumbers = [5, 10, 15, 20, 25, 30, 40, 50]
  const timePercent = timeRemaining / timeGoal
  const [goalSet, setGoalSet] = useState(false)
  const [question, setQuestion] = useState("")
  const [warning, setWarning] = useState("")
  const handleAddGoal = () => {
    setGoalSet(true)
  }

  useInterval(() => {
    if (isActive && timeRemaining > 0) {
      setTimeRemaining(timeRemaining - 1)
    }
  }, 1000)

  useEffect(() => {
    if (isActive && timeRemaining === 0) {
      setViewResults(true)
    }
  }, [timeRemaining])

  const handleAddIdea = () => {
    const date = new Date()
    const newIdea = {
      id: ideaId,
      dateTime: date,
      text: text,
      position: ideaId,
      dismissed: false,
      isParent: false,
      parentId: null,
    }
    if (text.length > 0) {
      setIdeas([...ideas, newIdea])
      setText("")
      setIdeaId(ideaId + 1)
      setWarning("")
    } else setWarning("You have to type something!")
  }
  const handleStart = () => {
    setGameClock(timeGoal)
    setTimeRemaining(timeGoal)
    setIsActive(true)
    setGameOn(true)
  }
  const handleDecreaseTimeGoal = () => {
    if (timeGoal > 0) {
      setTimeGoal(timeGoal - 1)
    }
  }
  const handleIdeasToggle = () => {
    if (ideasSet) {
      setIdeasSet(!ideasSet)
    }
  }
  const handleTimeToggle = () => {
    if (timeSet) {
      setTimeSet(!timeSet)
    }
  }
  const handleSetIdeaGoal = val => {
    setIdeaGoal(val)
    setIdeasSet(true)
  }

  const handleReset = () => {
    setIdeas([])
    setTimeRemaining(30)
    setGameClock(30)
    setTimeGoal(30)
    setViewResults(false)
    setGoalSet(false)
    setTimeSet(false)
    setIdeasSet(false)
    setIsActive(false)
    setGameOn(false)
    setQuestion("")
  }
  return (
    <div>
      <h2>Divergent Thinking Game</h2>
      <Introduction />
      <h3>Step 1: Ask a Question</h3>
      <p>What problem do you want to solve? What do you want to figure out?</p>
      <DisplayText>{goalSet ? question : ""}</DisplayText>
      <>
        {goalSet ? (
          <TextButton onClick={() => setGoalSet(false)}>
            Edit Question
          </TextButton>
        ) : (
          <form
            onSubmit={event => {
              event.preventDefault()
              handleAddGoal()
            }}
          >
            <Container style={{ alignItems: "baseline" }}>
              <div style={{ display: "flex", flexGrow: 3 }}>
                <InputBox
                  type="text"
                  placeholder="What do you want to know?"
                  value={question}
                  onChange={event => setQuestion(event.target.value)}
                />
              </div>
              <div style={{ display: "flex", flexGrow: 1 }}>
                <SquareButton type="submit">
                  <CheckIcon style={{ fontSize: "15px" }} />
                </SquareButton>
              </div>
            </Container>
          </form>
        )}
      </>
      <h3>Step 2: Set a goal</h3>
      <p>
        Set the timer and give yourself a challenging goal. How many ideas do
        you want to discover in this short window of time?
      </p>
      <Goals>
        <Goal onClick={handleIdeasToggle}>
          <>
            <DisplayText >
              Goal:
              <br />
              {ideasSet ? (
                <>{ideaGoal} Ideas</>
              ) : (
                <div style={{marginTop: "0.5rem"}}>
                  <SelectBox
                    id="goal-select"
                    value={ideaGoal}
                    onChange={event => handleSetIdeaGoal(event.target.value)}
                  >
                    {quickNumbers.map((numb, index) => (
                      <option key={index} value={numb}>
                        {numb}
                      </option>
                    ))}
                  </SelectBox>{" "}
                  Ideas
                  <SquareButton onClick={() => setIdeasSet(true)}>
                    <CheckIcon style={{ fontSize: "15px" }} />
                  </SquareButton>
                </div>
              )}
            </DisplayText>
          </>
        </Goal>
        <Goal onClick={handleTimeToggle}>
          {timeSet ? (
            <DisplayText>
              Time: <br/>
              {timeGoal} seconds
            </DisplayText>
          ) : (
            <>
              <DisplayText>Time Limit: {timeSet ? timeGoal : <br />}</DisplayText>

              {!timeSet && (
               <div style={{marginTop: "0.5rem"}}>
                  <TimeSelector>
                    <div style={{ display: "flex", flexGrow: 1 }}>
                      <CircleButton onClick={handleDecreaseTimeGoal}>
                        -
                      </CircleButton>
                    </div>
                    <div style={{ display: "flex", flexGrow: 1 }}>
                    <DisplayText >
                      <NumberInput
                        type="number"
                        id="time-goal"
                        value={timeGoal}
                        onChange={event => setTimeGoal(event.target.value)}
                      />
                     </DisplayText></div>
                   
                    <div style={{ display: "flex", flexGrow: 1 }}>
                      <CircleButton onClick={() => setTimeGoal(timeGoal + 1)}>
                        +
                      </CircleButton>
                    </div>
                    <div style={{ display: "flex", flexGrow: 1 }}>
                      <SquareButton onClick={() => setTimeSet(true)}>
                        <CheckIcon style={{ fontSize: "15px" }} />
                      </SquareButton>
                    </div>
                    <div style={{ display: "flex", flexGrow: 1 }}> <label for="time-goal">* secs</label></div>
                 
                  </TimeSelector>
                 
                </div>
              )}
            </>
          )}
        </Goal>
        
      </Goals>
      <h3>Step 3: Fire off some ideas!</h3>
      {!goalSet || !timeSet || !ideasSet ? "Ask a question, set a goal, and set a time limit to proceed with the game.":""}
      {goalSet && timeSet && (
        <>
          {gameOn ? (
            <>
              <Goals>
                <MetricBox>
                  <div>
                    <DisplayText>
                      Completed: <br />
                      {ideas.length} / {ideaGoal} Ideas
                    </DisplayText>

                    <GoalMeter
                      id="ideas-collected"
                      value={ideas.length}
                      min="0"
                      max={ideaGoal}
                    >
                      {ideas.length} out of {ideaGoal}
                    </GoalMeter>
                  </div>
                </MetricBox>
                <MetricBox>
                  {timeRemaining > 0 ? (
                    <>

                      <DisplayText>
                        {timeRemaining}{" "}
                        {timeRemaining === 1 ? "second" : "seconds"} left
                      </DisplayText>
                      <GoalMeter id="time-remaining" value={timePercent}>
                        {timePercent}
                      </GoalMeter>
                      
                    </>
                  ) : (
                    <DisplayText>Time's Up!</DisplayText>
                  )}
                </MetricBox>
              </Goals>
             
              <Container>
                <p>
                  We'll store your ideas in the background so they aren't here
                  to distract you. You'll be able to view and evaluate your
                  ideas after the time is up.
                </p>
              </Container>
              {viewResults ? (
               <DisplayText> {ideas.length > 0 ? 
                  `Great job! You came up with ${ideas.length}${" "}
                  ${ideas.length === 1 ? "idea" : "ideas"}. View your results
                  below.`
                : "You didn't come up with any ideas. Try again!"}</DisplayText> 
              ) : (
                <>
                  {warning ? warning : ""}
                  <form
                    onSubmit={event => {
                      event.preventDefault()
                      handleAddIdea()
                    }}
                  >
                    <Container style={{ alignItems: "baseline" }}>
                      <div style={{ display: "flex", flexGrow: 3 }}>
                        <InputBox
                          type="text"
                          placeholder="Punch in an idea"
                          value={text}
                          onChange={event => setText(event.target.value)}
                        />
                      </div>
                      <div style={{ display: "flex", flexGrow: 1 }}>
                        <SquareButton type="submit">
                          <CheckIcon style={{ fontSize: "15px" }} />
                        </SquareButton>
                      </div>
                    </Container>
                  </form>
                  <GoalMeter id="time-remaining" value={timePercent}>
                        {timePercent}
                      </GoalMeter>
                </>
              )}
            </>
          ) : (
            <div>
              <p>
                Let's do this! As soon as you click "Start!", you'll have{" "}
                {timeGoal} seconds to come up with {ideaGoal}{" "}
                {ideaGoal === 1 ? "solution" : "solutions"} to:
              </p>
              <DisplayText>{question}</DisplayText>
              <p>
                Remember, we're going for quantity here. If you reach your goal
                and you still have time remaining, just keep going! Are you
                ready?
              </p>
              <ResetButton onClick={handleStart}>Start</ResetButton>
            </div>
          )}
        </>
      )}

      {viewResults &&  (
        <>
        {ideas.length > 0 ? 
        <>
          <h3>Step 4: Review your ideas</h3>
          <p>
            Position your ideas in order of best to worst. Dismiss any that you
            really don't like or that are not at all helpful. Elaborate on ideas
            that could use more thought or further clarification.
          </p>
          <IdeaList ideas={ideas} setIdeas={setIdeas} />
          <h3>Step 5: Take it with you (optional)</h3>
          <p>Copy and paste wherever you keep your notes. Use Google Docs? After you paste the text, select all and then go to the "format" tab and pick "clear formatting".</p>
          <div>
            <PrintList ideas={ideas} />
          </div>
          <h3>Step 6: Start over!</h3>
          <p>
            Often, we come up with solutions that generate even more questions.
            Keep it flowing!
          </p>
          <ResetButton onClick={handleReset}>Start Over</ResetButton>
        </>
      : <ResetButton onClick={handleReset}>Try Again</ResetButton> }</>)}
    </div>
  )
}

export default DivergentGame

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
const Goals = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 600px) {
      flex-direction: column; 
  }
`
const Goal = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--color-heading);
  padding: 0.5rem;
  margin: 0.5rem;
  border-radius: 10px;
 flex-grow: 1;
`
const MetricBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--color-heading-black);
  padding: 0.5rem;
  margin: 0.5rem;
  border-radius: 10px;
  flex-grow: 1;
`
const DisplayText = styled.h2`
  color: #fff;
  font-size: 25px;
  margin-top: 0px;
  margin-bottom: 0px;
`
const InputBox = styled.input`
  border: 2px solid var(--color-heading);
  border-radius: 4px;
  background: var(--color-quote-background);
  color: #fff;
  width: 100%;
  height: 40px;
  margin-bottom: 15px;
  padding: 5px;
  maxlength: 50;
`
const SelectBox = styled.select`
  border: 2px solid var(--color-heading);
  border-radius: 4px;
  background: var(--color-quote-background);
  color: #fff;
  height: 40px;
  padding: 5px;
  maxlength: 4;
`

const ResetButton = styled.button`
  margin: 1rem;
  height: 3rem;
  width: 85%;
  cursor: pointer;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  border: 2px solid var(--color-text-light);
  background-color: var(--color-heading-black);
  border-radius: 15px;
`
const CircleButton = styled.button`
  margin: 0.5rem;
  height: 1.8rem;
  width: 1.8rem;
  cursor: pointer;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  border: 2px solid var(--color-text-light);
  background-color: var(--color-heading-black);
  border-radius: 20px;
`
const SquareButton = styled.button`
  margin: 0.5rem;
  height: 1.8rem;
  width: 1.8rem;
  cursor: pointer;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  border: 2px solid #fff;
  background-color: var(--color-heading-black);
  border-radius: 5px;
`
const TimeSelector = styled.div`
  display: flex;

  justify-content: center;
  margin: 0px;

`
const NumberInput = styled.input`
  border: 2px solid var(--color-heading);
  border-radius: 4px;
  background: var(--color-quote-background);
  color: #fff;
  width: 5rem;
  height: 40px;
  padding: 5px;
  maxlength: 4;
`
const GoalMeter = styled.meter`
  width: 100%;
  height: 1.2rem;
`
const TextButton = styled.button`
  color: var(--color-heading);
  font-size: 14px;
  background-color: var(--color-accent);
  border: 0px;
  cursor: pointer;
`

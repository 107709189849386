import React from "react"
import { Link } from "gatsby"

import { MDXProvider } from "@mdx-js/react"
import {
  //   Headings,
  //   Code,
  Blockquote,
  //   Example,
  PrismSetup,
} from "./src/components/Shared"

const components = {
  Link: Link,
  //   h2: Headings.myH2,
  //   h4: Headings.myH4,
  //   inlineCode: Code,
  blockquote: Blockquote,
  // pre: Example,
  pre: PrismSetup,
}
export const wrapMDX = ({ element }) => {
  return <MDXProvider components={components}>{element}</MDXProvider>
}

import React, { useState, useRef, useEffect } from "react"
import { Stage, Layer, Image, Line, Text } from "react-konva"
import useContainerDimensions from "../../hooks/useContainerDimensions"
import styled from "styled-components"
import "../../../style.css"
import ShareProject from "../../Shared/ShareProject"
import DownloadImage from "./DownloadImage"

const Create = () => {
  const [tool, setTool] = useState("pen")
  const [lines, setLines] = useState([])
  const [text, setText] = useState("")
  const [thoughts, setThoughts] = useState("")
  const [previewMode, setPreviewMode] = useState(false)
  const [preview, setPreview] = useState("")
  const isDrawing = useRef(false)
  var stageContainer = useRef()
  var stage = useRef()
  const { width, height } = useContainerDimensions(stageContainer)
  const stageHeight = width * 0.8

  // Touchscreen
  // var canvas = document.createElement("canvas")
  // canvas.width = width
  // canvas.height = stageHeight
  function disableScroll() {
    // Get the current page scroll position
    const scrollTop = document.documentElement.scrollTop
    const scrollLeft = document.documentElement.scrollLeft
    // if any scroll is attempted,
    // set this to the previous value
    window.onscroll = function () {
      window.scrollTo(scrollLeft, scrollTop)
    }
  }
  function enableScroll() {
    window.onscroll = function () {}
  }
  const handleMouseDown = e => {
    isDrawing.current = true
    const pos = e.target.getStage().getPointerPosition()
    setLines([...lines, { tool, points: [pos.x, pos.y] }])
  }
  const handleTouchStart = e => {
    isDrawing.current = true
    disableScroll()
    const pos = e.target.getStage().getPointerPosition()
    setLines([...lines, { tool, points: [pos.x, pos.y] }])
  }

  const handleMouseMove = e => {
    // no drawing - skipping
    if (!isDrawing.current) {
      return
    }
    const stage = e.target.getStage()

    const point = stage.getPointerPosition()

    let lastLine = lines[lines.length - 1]
    // add point
    lastLine.points = lastLine.points.concat([point.x, point.y])

    // replace last
    lines.splice(lines.length - 1, 1, lastLine)
    setLines(lines.concat())
  }
  const handleTouchMove = e => {
    // no drawing - skipping
    if (!isDrawing.current) {
      return
    }
    const stage = e.target.getStage()

    const point = stage.getPointerPosition()

    let lastLine = lines[lines.length - 1]
    // add point
    lastLine.points = lastLine.points.concat([point.x, point.y])

    // replace last
    lines.splice(lines.length - 1, 1, lastLine)
    setLines(lines.concat())
  }

  const handleMouseUp = () => {
    isDrawing.current = false
  }
  const handleTouchEnd = () => {
    isDrawing.current = false
    enableScroll()
  }

  const handlePreviewImage = () => {
    var imgData = stage.current.toDataURL()
    setPreview(imgData)
    setPreviewMode(true)
  }
  return (
    <Container ref={stageContainer}>
      <div style={previewMode ? { display: "none" } : null}>
        <ButtonGroup>
          <PenBox
            onClick={() => setTool("pen")}
            style={tool === "pen" ? { backgroundColor: "#fff" } : null}
          >
            Draw
          </PenBox>
          <EraserBox
            onClick={() => setTool("eraser")}
            style={tool === "eraser" ? { backgroundColor: "#fff" } : null}
          >
            Erase
          </EraserBox>
          <ClearBox onClick={() => setLines([])}>Clear</ClearBox>
        </ButtonGroup>
        <Stage
          ref={stage}
          width={width}
          height={stageHeight}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          onMouseDown={handleMouseDown}
          onMousemove={handleMouseMove}
          onMouseup={handleMouseUp}
        >
          <Layer>
            {lines.map((line, i) => (
              <Line
                key={i}
                points={line.points}
                stroke="#b4dcfd"
                strokeWidth={5}
                tension={0.5}
                lineCap="round"
                globalCompositeOperation={
                  line.tool === "eraser" ? "destination-out" : "source-over"
                }
              />
            ))}
          </Layer>
        </Stage>
        <div style={{ padding: "15px" }}>
          <InputBox
            type="text"
            placeholder="What is it?"
            value={text}
            onChange={event => setText(event.target.value)}
          />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button onClick={handlePreviewImage}>Preview & Download</Button>
          </div>
        </div>
      </div>
      {previewMode && (
        <DownloadImage
          preview={preview}
          text={text}
          setPreviewMode={setPreviewMode}
        />
      )}
    </Container>
  )
}

export default Create
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  border: 2px solid white;
`
const PenBox = styled.button`
  margin: 0.5rem 0.1rem 0rem 0rem;
  height: 1.8rem;
  width: 4rem;
  cursor: pointer;
  text-align: center;
  font-size: 1.1rem;
  font-weight: bold;
  border: 1.1ppx solid var(--color-text-light);
  background-color: var(--color-primary);
  border-radius: 15px 0px 0px 15px;
`
const EraserBox = styled.button`
  margin: 0.5rem 0.1rem 0rem 0rem;
  height: 1.8rem;
  width: 4rem;
  cursor: pointer;
  text-align: center;
  font-size: 1.1rem;
  font-weight: bold;
  border: 1.1ppx solid var(--color-text-light);
  background-color: var(--color-primary);
  border-radius: 0px 0px;
`
const ClearBox = styled.button`
  margin: 0.5rem 0.1rem 0rem 0rem;
  height: 1.8rem;
  width: 4rem;
  cursor: pointer;
  text-align: center;
  font-size: 1.1rem;
  font-weight: bold;
  border: 1.1ppx solid var(--color-text-light);
  background-color: var(--color-primary);
  border-radius: 0px 15px 15px 0px;
`
const TextBox = styled.textarea`
  border: 2px solid var(--color-heading);
  border-radius: 4px;
  background: var(--color-quote-background);
  color: #fff;
  width: 100%;
  height: 120px;
  margin-bottom: 15px;
  padding: 5px;
`
const InputBox = styled.input`
  border: 2px solid var(--color-heading);
  border-radius: 4px;
  background: var(--color-quote-background);
  color: #fff;
  width: 100%;
  height: 40px;
  margin-bottom: 15px;
  padding: 5px;
  maxlength: 50;
`
const Collapse = styled.div`
  display: block;
  .in {
    display: none;
  }
`
const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
`
const Button = styled.button`
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  height: 2rem;
  width: 10rem;
  cursor: pointer;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  border: 2px solid var(--color-text-light);
  background-color: var(--color-heading-black);
  border-radius: 15px;
  :hover {
    background-color: var(--color-heading);
    border: 2px solid var(--color-heading);
  }
`

import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { glossaryTerms } from "./GlossaryTerms"
import { Link } from "gatsby"

const Define = ({ term }) => {
  const [definition, setDefinition] = useState("")
  const [references, setReferences] = useState([])
  const def = glossaryTerms.find(gt => gt.term === term)

  const handleDefinition = () => {
    setDefinition(def.definition)
    def.references && setReferences(def.references)
  }
  return (
    <Definition
      onMouseOver={handleDefinition}
      onMouseOut={() => setDefinition("")}
    >
      {term}
      <span class="tooltiptext">
        <strong>{term}</strong>: {definition}
        <br />
        <Link to="/glossary" state={{ termRef: term }}>
          View in Glossary
        </Link>
      </span>
    </Definition>
  )
}

export default Define

const Definition = styled.span`
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted #555555; /* If you want dots under the hoverable text */

  /* Tooltip text */
  .tooltiptext {
    visibility: hidden;
    padding: 5px 0;
    border-radius: 6px;

    /* Custom style */
    background-color: #2d2d2d;
    color: #fff;
    text-align: left;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    width: 240px;
    top: 100%;
    left: 50%;
    padding: 10px 10px;
    margin-left: -100px; /* Use half of the width (120/2 = 60), to center the tooltip */
  }
  :hover .tooltiptext {
    visibility: visible;
  }
`

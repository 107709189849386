import React, { useState, useEffect } from "react"
import styled from "styled-components"
import "../../../style.css"

import Images from "./Images"
const RandomImage = () => {
  const [selectedNumbers, setSelectedNumbers] = useState([])
  const numberList = [...Array(12).keys()]
  const [notification, setNotification] = useState("pick 3 numbers")
  const [view, setView] = useState("numbers")

  const handleSelection = selectedValue => {
    const selection = selectedValue
    if (selectedNumbers.includes(selection)) {
      const updatedList = selectedNumbers.filter(n => n != selection)
      setSelectedNumbers(updatedList)
    } else if (selectedNumbers.length <= 2)
      setSelectedNumbers([...selectedNumbers, selection])
    else setNotification("Pick only 3 numbers")
  }
  const NumberBox = () => {
    return (
      <>
        <div style={{ width: "100%" }}>
          <Heading>Dial Up Ideas</Heading>
          <p>
            <strong>Dial-up-ideas</strong> is a lateral thinking exercise
            designed to train your brain to make connections between seemingly
            unrelated items.
          </p>
          <p>
            Begin by picking three numbers at random. Then, follow the
            instructions in the next step.
          </p>
        </div>
        <div style={{ width: "100%" }}>
          {numberList.map(index => {
            const buttonValue = index + 1
            return (
              <ButtonBox
                key={buttonValue}
                style={
                  selectedNumbers.includes(buttonValue)
                    ? {
                        color: "var(--color-accent)",
                        backgroundColor: "var(--color-primary)",
                      }
                    : {
                        color: "var(--color-primary)",
                        backgroundColor: "var(--color-accent)",
                      }
                }
                onClick={() => handleSelection(buttonValue)}
              >
                {index + 1}
              </ButtonBox>
            )
          })}
        </div>
        <SubmitButton
          onClick={() => setView("images")}
          disabled={selectedNumbers.length < 3}
        >
          {selectedNumbers.length === 3 ? "Next" : notification}
        </SubmitButton>
      </>
    )
  }
  function getView(view) {
    switch (view) {
      case "numbers":
        return <NumberBox />
      case "images":
        return <Images selectedNumbers={selectedNumbers} setView={setView} />
      default:
        return <NumberBox />
    }
  }
  return <>{getView(view)}</>
}

export default RandomImage
const Heading = styled.h1`
  font-size: 33px;
  text-align: center;
  color: var(--color-heading);
`
const ButtonBox = styled.button`
  margin: 0.5rem 0.25rem;
  height: 3rem;
  width: 30%;
  cursor: pointer;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  border: 2px solid var(--color-text-light);
  background-color: var(--color-quote-background);
  border-radius: 15px;
`

const SubmitButton = styled.button`
  margin: 1rem;
  height: 3rem;
  width: 85%;
  cursor: pointer;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  border: 2px solid var(--color-text-light);
  background-color: var(--color-heading-black);
  border-radius: 15px;
`

// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"

// Highlighting for code blocks
import "prismjs/themes/prism-twilight.css"
import React from "react"
import { wrapMDX } from "./root-mdx"

export const wrapRootElement = wrapMDX

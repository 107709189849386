import React, { useContext, useState, useRef, useEffect } from "react"
import { Text, Stage, Layer, Image } from "react-konva"

import useContainerDimensions from "../../hooks/useContainerDimensions"
import polaroid from "../../../images/polaroid.png"
import useImage from "use-image"
import styled from "styled-components"
import "../../../style.css"

const DownloadImage = ({ preview, text, setPreviewMode }) => {
  const previewContainer = useRef(null)
  const previewStage = useRef(null)
  const { width, height } = useContainerDimensions(previewContainer)
  const stageHeight = width * 1.2

  const PolaroidImage = () => {
    const [image] = useImage(polaroid)
    return <Image image={image} width={width} height={stageHeight} />
  }
  const PreviewImage = () => {
    const [image] = useImage(preview)
    return (
      <Image
        image={image}
        x={50}
        y={50}
        width={width * 0.8}
        height={stageHeight * 0.6}
      />
    )
  }
  function downloadURI(uri, name) {
    var link = document.createElement("a")
    link.download = name
    link.href = uri
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    // delete link
  }
  // Works but does not resize
  const handleDownload = () => {
    var dataURL = previewStage.current.toDataURL()
    downloadURI(dataURL, "dial_up_idea.png")
  }

  return (
    <div ref={previewContainer} style={{ width: "100%" }}>
      <Stage ref={previewStage} width={width} height={stageHeight}>
        <Layer>
          <PolaroidImage />
        </Layer>
        <Layer>
          <PreviewImage />
        </Layer>
        <Layer>
          <Text
            text={text}
            x={width * 0.05}
            y={stageHeight * 0.75}
            fontSize={20}
          />
        </Layer>
      </Stage>
      <ButtonGroup>
        <DownloadButton onClick={() => setPreviewMode(false)}>
          Back to Edit
        </DownloadButton>
        <DownloadButton onClick={handleDownload}>Save (png)</DownloadButton>
      </ButtonGroup>
    </div>
  )
}

export default DownloadImage
const DownloadButton = styled.button`
  margin: 1rem;
  height: 2rem;
  width: 12rem;
  cursor: pointer;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  border: 2px solid var(--color-text-light);
  background-color: var(--color-heading-black);
  border-radius: 15px;
  :hover {
    background-color: var(--color-heading);
    border: 2px solid var(--color-heading);
  }
`
const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
`
